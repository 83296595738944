import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../models/Contact';

@Injectable({
  providedIn: 'root',
})
export class ContactStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Contact[]> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'contacts',
    });

    return this.http.get<Contact[]>(this.apiUrl, { headers });
  }

  getSingle(uid: string) {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'contacts',
      'X-Param': '/' + uid,
    });

    return this.http.get<Contact>(this.apiUrl, { headers });
  }
}
