<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h6 class="card-header">
    <cds-icon shape="factory" size="md"></cds-icon>
    Firmen
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h6>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error?.status">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error?.status }} |
              {{ error?.statusText }}
              <ng-container *ngIf="error?.error.name">
                | {{ error?.error.name }} | {{ error?.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <button
        class="btn btn-outline"
        (click)="onSelectedEdit()"
        [disabled]="!selected || !selected._id"
      >
        <cds-icon shape="dot-circle"></cds-icon> bearbeiten
      </button>

      <clr-datagrid
        [clrDgLoading]="loading"
        [clrDgRowSelection]="true"
        [(clrDgSingleSelected)]="selected"
        [clrUseLocalStoreOnly]="true"
        [clrStatePersistenceKey]="{
          key: 'statePersistence.users.datagrid.factories',
          serverDriven: true,
        }"
        [clrPaginationDescription]="
          '{{first}} - {{last}} von {{total}} Einträgen'
        "
      >
        <clr-dg-placeholder>
          Wir konnten keine Einträge finden!
        </clr-dg-placeholder>

        <clr-dg-column [clrDgField]="'Nr'">
          <ng-container *clrDgHideableColumn="{ hidden: false }">
            <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
          </ng-container>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'Name'">
          <ng-container *clrDgHideableColumn="{ hidden: false }">
            <cds-icon shape="tag"></cds-icon>&nbsp;Name
          </ng-container>
        </clr-dg-column>

        <clr-dg-row
          *clrDgItems="let factory of factories"
          [clrDgItem]="factory"
          [clrDgSelectable]="true"
        >
          <clr-dg-cell>
            <a [routerLink]="['/factories', factory.Uid]">
              {{ factory.Nr }}
            </a>
          </clr-dg-cell>
          <clr-dg-cell>
            <a [routerLink]="['/factories', factory.Uid]">
              {{ factory.Name }}
            </a>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
              >Einträge pro Seite</clr-dg-page-size
            >
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<app-users-rights-factories-form
  *ngIf="detail"
  [factory]="selected"
  (done)="onSelectedClose($event)"
>
</app-users-rights-factories-form>
